<template>
  <div>
    <v-dialog
      v-model="dialogDelAbrechnung"
      width="500"
    >
      <v-card>
        <v-card-title>
          Wirklich löschen?
        </v-card-title>

        <v-card-text>
          Die gelöschten Daten können nicht wieder hergestellt werden.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            text
            @click="dialogDelAbrechnung = false"
          >
            Abbrechen
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="delAbrechnung();"
            :loading="$store.state.f420.loading.delAbrechnung"
          >
            Löschen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      scrollable
      fullscreen transition="dialog-bottom-transition"
      v-model="showDialog"
      width="500"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary darken-1"
        >
          <v-btn
            icon
            dark
            @click="showDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <v-row align="center">
              <v-col>
                {{ mitarbeiter.name }}
              </v-col><v-col class="pt-10" v-if="$vuetify.breakpoint.smAndUp">
                <v-select
                  dense
                  :items="monate"
                  v-model="monat"
                  item-text="name"
                  item-value="value"
                  label="Monat"
                />
              </v-col><v-col class="pt-10" v-if="$vuetify.breakpoint.smAndUp">
                <v-text-field
                  dense
                  label="Jahr"
                  v-model="jahr"
                  type="number"
                />
              </v-col>
            </v-row>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <!-- <v-btn
              dark
              text
              @click="showDialog = false"
              :disabled="checkIsChanged"
            >
              Speichern
            </v-btn> -->
          </v-toolbar-items>
        </v-toolbar>
        <!-- <v-card-title>
          Lohnabrechnung
        </v-card-title> -->

        <v-card-text
          class="pa-0"
          style="height:100%;overflow-x:hidden;"
        >
          <v-container
            class="d-flex flex-row justify-center align-center"
            style="height:30%;"
            v-if="loading"
          >
            Lade Daten...
          </v-container>
          <v-container fluid class="pa-0" v-else>
            <v-row class="pt-6 px-4 primary" v-if="!$vuetify.breakpoint.smAndUp">
              <v-col>
                <v-select
                  dense dark
                  :items="monate"
                  v-model="monat"
                  item-text="name"
                  item-value="value"
                  label="Monat"
                />
              </v-col>
              <v-col>
                <v-text-field
                  dense dark
                  label="Jahr"
                  v-model="jahr"
                  type="number"
                />
              </v-col>
            </v-row>
            <v-row no-gutters :class="{
                'd-flex': true,
                'flex-row': !$vuetify.breakpoint.smAndDown,
                'flex-column-reverse': $vuetify.breakpoint.smAndDown,
            }">
              <v-col align="center" cols="12" md="3" lg="2">
                <div
                  :style="{
                    'display': 'flex',
                    'flex-direction': 'column',
                    'align-items': 'center',
                    'justify-content': 'flex-start',
                    'position': 'sticky',
                    'top': '0px',
                    'height': !$vuetify.breakpoint.smAndDown ? 'calc(100vh - 65px)' : 'auto',
                    'overflow-y': !$vuetify.breakpoint.smAndDown ? 'auto' : 'hidden',
                    'overflow-x': 'hidden',
                  }"
                >
                  <v-card class="mt-4 pa-2" width="90%">
                    <v-btn small @click="dialogLohngruppen = true">Lohngruppen</v-btn>
                    <v-dialog
                      v-model="dialogLohngruppen"
                      width="500px"
                    >
                      <v-card>
                        <v-card-title>Lohngruppen anpassen</v-card-title>
                        <v-card-text>
                          <v-row>
                            <v-col
                              v-for="lohngruppe in lohngruppen" :key="lohngruppe.id"
                              cols="12" sm="4"
                            >
                              <v-text-field
                                v-model="lohngruppe.wert"
                                :label="`${lohngruppe.name} in €`"
                                type="number"
                                :disabled="nurVorschau"
                              />
                            </v-col>
                          </v-row>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer />
                          <v-btn small text color="primary"
                            @click="dialogLohngruppen = false"
                          >ok</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-simple-table v-if="!nurVorschau" dense>
                      <template v-slot:default>
                        <tbody>
                          <tr>
                            <td colspan="2">
                              <v-select
                                dense
                                class="mt-6"
                                label="Standard Lohngruppe"
                                v-model="abrechnung.standard_lohngruppe"
                                :items="$store.state.f420.store.settings.lohngruppen"
                                item-text="name"
                                item-value="id"
                                @change="changedStandardLohngruppe();"
                                :disabled="nurVorschau"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td style="width:50%;">
                              <v-text-field
                                dense
                                class="mt-6"
                                label="Von"
                                v-model="abrechnung.standard_arbeitsbeginn"
                                @change="changedStandardZeitVon();"
                                :disabled="nurVorschau"
                              />
                            </td>
                            <td style="width:50%;">
                              <v-text-field
                                dense
                                class="mt-6"
                                label="Bis"
                                v-model="abrechnung.standard_arbeitsende"
                                @change="changedStandardZeitBis();"
                                :disabled="nurVorschau"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <v-text-field
                                dense
                                class="mt-6"
                                label="Pausen"
                                v-model="abrechnung.standard_pausen"
                                @change="changedStandardPausen();"
                                :disabled="nurVorschau"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card>
                  <v-card class="mt-4 pa-2" width="90%">
                    <v-simple-table dense>
                      <template v-slot:default>
                        <tbody>
                          <tr>
                            <td colspan="2">
                              <v-text-field
                                dense
                                class="mt-6"
                                label="Stunden"
                                v-model="abrechnung.vertrag_stunden"
                                type="number"
                                :prefix="`${getUebersichtData.stunden} /`"
                                :disabled="nurVorschau"
                              />
                            </td>
                          </tr>
                          <tr v-if="abrechnung.vertrag_stunden > 0 && !nurVorschau">
                            <td colspan="2">
                              <v-select
                                dense
                                class="mt-6"
                                label="Vertrag Lohngruppe"
                                v-model="abrechnung.vertrag_lohngruppe"
                                :items="$store.state.f420.store.settings.lohngruppen"
                                item-text="name"
                                item-value="id"
                                :disabled="nurVorschau"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card>
                  <v-card class="mt-4 pa-2" width="90%">
                    <v-simple-table dense>
                      <template v-slot:default>
                        <tbody>
                          <tr>
                            <td>Überstunden:</td>
                            <td>
                              {{ getUebersichtData.stundenSaldo }}
                              <span class="success--text"
                                v-if="getUebersichtData.ueberstunden > '0'"
                              >
                                +{{ getUebersichtData.ueberstunden }}
                              </span>
                              <span class="error--text"
                                v-if="getUebersichtData.ueberstunden < '0'"
                              >
                                {{ getUebersichtData.ueberstunden }}
                              </span>
                            </td>
                          </tr>
                          <tr v-if="monat === 1">
                            <td colspan="2">
                              <v-text-field
                                dense
                                class="mt-6"
                                label="Urlaub aus Vorjahr:"
                                v-model="urlaubAusVorjahr"
                                type="number"
                                :disabled="nurVorschau"
                              />
                            </td>
                            <!-- <td>
                              Urlaub aus Vorjahr:
                              <div>
                                <v-btn x-small text @click="urlaubAusVorjahr -= 1">
                                  <v-icon x-small>mdi-minus-box</v-icon>
                                </v-btn>
                                <v-btn x-small text @click="urlaubAusVorjahr += 1">
                                  <v-icon x-small>mdi-plus-box</v-icon>
                                </v-btn>
                              </div>
                            </td>
                            <td>
                              {{ getUebersichtData.urlaubAusVorjahr }}
                            </td> -->
                          </tr>
                          <tr>
                            <td>Resturlaub:</td>
                            <td>
                              {{ getUebersichtData.urlaubSaldo }}
                              <span class="error--text">-{{ getUebersichtData.urlaubstage }}</span>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <v-text-field
                                dense
                                class="mt-6"
                                label="Überstunden auszahlen"
                                v-model="abrechnung.ueberstundenAusgezahlt.stunden"
                                type="number"
                                :disabled="nurVorschau"
                              />
                            </td>
                          </tr>
                          <tr v-for="(sonderzahlung, idx) in sonderzahlungen" :key="idx">
                            <td colspan="2">
                              <v-text-field
                                dense
                                class="mt-6"
                                label="Text"
                                v-model="sonderzahlung.text"
                                :disabled="nurVorschau"
                              />
                              <v-text-field
                                dense
                                class="mt-6"
                                label="Wert in € (Brutto)"
                                v-model="sonderzahlung.wert"
                                type="number"
                                :disabled="nurVorschau"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2" align="center">
                              <v-btn
                                x-small
                                @click="addSonderzahlung();"
                                :disabled="nurVorschau"
                              ><v-icon small left>mdi-plus-box</v-icon> Sonderzahlung</v-btn>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card>
                  <v-card class="my-4 pa-2 d-flex flex-row justify-space-around" width="90%">
                    <v-btn
                      v-if="
                        (
                          !checkIsChanged || abrechnung.id === 0
                        ) && letzteAbrechnung <= `${jahr}-${monat}`"
                      color="primary"
                      text small
                      @click="saveAbrechnung();"
                      :disabled="checkIsChanged"
                      :loading="$store.state.f420.loading.saveAbrechnung"
                    >
                      <v-row v-if="abrechnung.id === 0" align="center">
                        <v-icon left>mdi-content-save</v-icon>
                        Speichern
                      </v-row>
                      <v-row v-else align="center">
                        <v-icon left>mdi-refresh</v-icon>
                        Aktualisieren
                      </v-row>
                    </v-btn>
                    <v-btn
                      v-else-if="abrechnung.id > 0"
                      color="primary"
                      text small
                      @click="$store.dispatch('main/tryDownload', {
                        path: `f400-420/abrechnungen/${abrechnung.id}.pdf`,
                        name: downloadPdfFilename,
                        newTab: true,
                      });"
                    >
                      <v-row align="center">
                        <v-icon left>mdi-download-box-outline</v-icon>
                        PDF Ansehen
                      </v-row>
                    </v-btn>
                    <!-- <v-btn
                      small text
                      :disabled="this.abrechnung.id === 0"
                      @click="dialogDelAbrechnung = true;"
                    >
                      <v-icon small>mdi-delete</v-icon>
                    </v-btn> -->
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          text small
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-menu</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-if="
                            abrechnung.id > 0
                            && letzteAbrechnung <= `${jahr}-${monat}`
                          "
                          @click="saveAbrechnung();"
                        >
                          <v-list-item-title>
                            <v-icon left>mdi-refresh</v-icon>
                            Aktualisieren
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          :disabled="this.abrechnung.id === 0"
                          @click="$store.dispatch('main/tryDownload', {
                            path: `f400-420/abrechnungen/${abrechnung.id}.pdf`,
                            name: downloadPdfFilename,
                            newTab: false,
                          });"
                        >
                          <v-list-item-title>
                            <v-icon left>mdi-download-box-outline</v-icon>
                            PDF Download
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          :disabled="this.abrechnung.id === 0"
                          @click="dialogDelAbrechnung = true;"
                        >
                          <v-list-item-title>
                            <v-icon left>mdi-delete</v-icon>
                            Löschen
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-card>
                </div>
              </v-col>
              <v-col
                cols="12" md="9" lg="10"
                style="min-height:100%;overflow-y:auto;"
                class="d-flex align-center justify-center py-6"
                v-if="nurVorschau"
              >
                <div align="center">
                  Da bereits Lohnabrechnungen nach dieser erstellt wurden,
                  kann diese nicht mehr geändert werden.<br/>
                  <v-btn
                    small
                    class="ma-2"
                    @click="$store.dispatch('main/tryDownload', {
                      path: `f400-420/abrechnungen/${abrechnung.id}.pdf`,
                      name: downloadPdfFilename,
                      newTab: false,
                    });"
                  >
                    <v-icon left>mdi-download-box-outline</v-icon>PDF Download
                  </v-btn>
                </div>
              </v-col>
              <v-col
                cols="12" md="9" lg="10"
                style="height:100%;overflow-y:auto;"
                v-if="!nurVorschau"
              >
                <v-simple-table dense>
                  <template v-slot:default>
                    <!-- <thead>
                      <tr>
                        <th class="text-left">
                          Tag
                        </th>
                        <th class="text-left" style="min-width:130px;">
                          Status
                        </th>
                        <th class="text-left" style="width:100%;min-width:200px;">
                          Objekt
                        </th>
                        <th class="text-left" style="min-width:140px;">
                          LG
                        </th>
                        <th class="text-left">
                          Von
                        </th>
                        <th class="text-left">
                          Bis
                        </th>
                        <th class="text-left">
                          Pause
                        </th>
                        <th></th>
                      </tr>
                    </thead> -->
                    <tbody>
                      <tr
                        v-for="(tag, idx) in tage"
                        :key="idx"
                        :class="{
                          'grey': tag.isWeekend,
                          'lighten-2': tag.weekday === 0,
                          'lighten-3': tag.weekday === 6,
                          // 'white--text': tag.isWeekend,
                        }"
                      >
                        <td>{{ tag.dateText2_de }}</td>
                        <td style="min-width:150px;">
                          <v-select
                            :dark="tag.isWeekend && 1 > 2"
                            label="Status"
                            v-model="tag.status"
                            :items="moeglicheStatus"
                            item-text="name"
                            item-value="value"
                            @change="changedStatus(tag)"
                          />
                        </td>
                        <td class="pt-4" style="width:100%;min-width:200px;">
                          <v-autocomplete
                            :items="datasetObjekte"
                            no-data-text=""
                            hide-no-data
                            dense :dark="tag.isWeekend && 1 > 2"
                            v-model="tag.objekt"
                            type="text"
                            label="Objekt"
                            @update:search-input="searchObjekt($event)"
                            @change="changedObjekt()"
                            :disabled="tag.status === 0"
                          />
                        </td>
                        <td style="min-width:150px;">
                          <v-select
                            :dark="tag.isWeekend && 1 > 2"
                            label="Lohngruppe"
                            v-model="tag.idLohngruppe"
                            :items="$store.state.f420.store.settings.lohngruppen"
                            item-text="name"
                            item-value="id"
                            :disabled="tag.status === 0"
                          />
                        </td>
                        <td>
                          <v-text-field
                            dense :dark="tag.isWeekend && 1 > 2"
                            label="Von" class="mt-4"
                            v-model="tag.zeitVon"
                            type="time"
                            :disabled="tag.status === 0"
                          />
                        </td>
                        <td>
                          <v-text-field
                            dense :dark="tag.isWeekend && 1 > 2"
                            label="Bis" class="mt-4"
                            v-model="tag.zeitBis"
                            type="time"
                            :hint="berechneStundendifferenz(tag.zeitVon, tag.zeitBis)"
                            :disabled="tag.status === 0"
                          />
                        </td>
                        <td>
                          <v-text-field
                            dense :dark="tag.isWeekend && 1 > 2"
                            label="Pausen" class="mt-4"
                            v-model="tag.pausen"
                            type="time"
                            :disabled="tag.status === 0"
                          />
                        </td>
                        <td>
                          <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                text small
                                :dark="tag.isWeekend && 1 > 2"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon>mdi-menu</v-icon>
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item
                                @click="setTagStatus(tag, 0)"
                              >
                                <v-list-item-title>
                                  leeren
                                </v-list-item-title>
                              </v-list-item>
                              <v-list-item
                                @click="setTagStatus(tag, -1)"
                              >
                                <v-list-item-title>
                                  zurücksetzen
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <!-- <v-card-actions>
          <v-btn
            text small
            @click="showDialog = false"
          >
            schließen
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text small
            @click="showDialog = false"
          >
            zurücksetzen
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text small
            @click="showDialog = false"
          >
            speichern
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    idMitarbeiter: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    nurVorschau: false,
    dialogLohngruppen: false,
    dialogDelAbrechnung: false,
    loading: true,
    showDialog: false,
    letzteAbrechnung: '',
    jahr: null,
    monat: null,
    abrechnung: {
      jahr: null,
      monat: null,
    },
    datasetObjekte: [],
    tage: [],
    sonderzahlungen: [],
    stundenSaldo: 0,
    urlaubAusVorjahr: 0,
    urlaubSaldo: 0,
    abrechnungSnapshot: '',
    mitarbeiter: {},
    moeglicheStatus: [
      { name: 'Nicht', value: 0 },
      { name: 'Arbeit', value: 1 },
      { name: 'Feiertag', value: 2 },
      { name: 'Urlaub', value: 3 },
      { name: 'Krank', value: 4 },
      { name: 'Gleittag', value: 5 },
    ],
    monate: [
      { name: 'Januar', value: 1 },
      { name: 'Februar', value: 2 },
      { name: 'März', value: 3 },
      { name: 'April', value: 4 },
      { name: 'Mai', value: 5 },
      { name: 'Juni', value: 6 },
      { name: 'Juli', value: 7 },
      { name: 'August', value: 8 },
      { name: 'September', value: 9 },
      { name: 'Oktober', value: 10 },
      { name: 'November', value: 11 },
      { name: 'Dezember', value: 12 },
    ],
  }),
  computed: {
    downloadPdfFilename() {
      let res = `${this.mitarbeiter.name}_${this.abrechnung.jahr}`;
      res += `-${String(this.abrechnung.monat).padStart(2, '0')}`;
      res += '.pdf';
      return res;
    },
    checkIsChanged() {
      let res = false;
      const abrechnung = JSON.parse(JSON.stringify(this.abrechnung));
      abrechnung.tage = JSON.parse(JSON.stringify(this.tage));
      if (this.abrechnungSnapshot === JSON.stringify(abrechnung)) {
        res = true;
      }
      return res;
    },
    monatName() {
      let string = `${this.abrechnung.jahr}-`;
      string += String(this.abrechnung.monat).padStart(2, '0');
      string += '-01T00:00:00';
      const date = new Date(string).toLocaleString('de-de', {
        month: 'long',
        year: 'numeric',
      });
      return date;
    },
    getUebersichtData() {
      const summen = [0, 0, 0, 0, 0, 0];
      let urlaubstage = 0;
      this.tage.forEach((t) => {
        let j = 0;
        let p = 0;
        if (t.zeitVon !== '' && t.zeitBis !== '' && t.zeitBis > t.zeitVon) {
          if (t.zeitBis !== '' && t.zeitVon !== '') {
            j = new Date(`2000-01-01T${t.zeitBis}:00`).getTime() - new Date(`2000-01-01T${t.zeitVon}:00`).getTime();
            if (t.pausen !== '') {
              p = new Date(`2000-01-01T${t.pausen}:00`).getTime() - new Date('2000-01-01T00:00:00').getTime();
            }
            j -= p;
            j /= 1000;
            j /= 60;
            j /= 60;
          }
          summen[t.status] += j;
        }
        if (t.status === 3) {
          urlaubstage += 1;
        }
      });
      summen.forEach((s, idx) => {
        summen[idx] = Math.round(summen[idx] * 100) / 100;
      });
      let standardLohngruppe = this.$store.state.f420.store.settings.lohngruppen
        .filter((o) => o.id === this.abrechnung.standard_lohngruppe);
      if (standardLohngruppe.length > 0) {
        standardLohngruppe = standardLohngruppe[0].name;
      }
      const summenBezahlt = summen[1] + summen[2] + summen[3] + summen[4];
      let ueberstunden = summenBezahlt;
      ueberstunden -= this.abrechnung.vertrag_stunden;
      ueberstunden -= this.abrechnung.ueberstundenAusgezahlt.stunden;
      ueberstunden = Math.round(ueberstunden * 100) / 100;
      return {
        stunden: String(summenBezahlt).split('.').join(','),
        stundenSaldo: String(this.stundenSaldo).split('.').join(','),
        ueberstunden: String(ueberstunden).split('.').join(','),
        urlaubAusVorjahr: this.urlaubAusVorjahr,
        urlaubSaldo: this.urlaubSaldo + parseInt(this.urlaubAusVorjahr, 10),
        urlaubstage,
      };
    },
  },
  watch: {
    showDialog(neu) {
      if (neu === false) {
        this.$router.push({ path: '/420?' });
      }
    },
    idMitarbeiter() {
      this.checkDialog();
    },
    jahr() {
      this.checkDialog();
    },
    monat() {
      this.checkDialog();
    },
  },
  methods: {
    addSonderzahlung() {
      this.sonderzahlungen.push({
        text: '',
        wert: 0,
      });
    },
    berechneStundendifferenz(von, bis) {
      const d1 = new Date(`2000-01-01T${von}:00`).getTime();
      const d2 = new Date(`2000-01-01T${bis}:00`).getTime();
      let d = d2 - d1;
      if (d < 0) {
        d *= (-1);
      }
      d /= (1000 * 60 * 60);
      d = Math.round(d * 100) / 100;
      if (!d) {
        return '-- h';
      }
      return `${d} h`;
    },
    delAbrechnung() {
      this.$store.dispatch('f420/tryGetOrSaveSettings', {
        dataToSave: {
          type: 'delAbrechnung',
          idDelAbrechnung: this.abrechnung.id,
        },
      }).then(() => {
        let success = false;
        const m = this.$store.state.f420.store.mitarbeiter
          .filter((o) => o.id === this.idMitarbeiter);
        if (m.length > 0) {
          const a = m[0].lohnabrechnungen
            .filter((o) => o.jahr === this.jahr && o.monat === this.monat);
          if (a.length <= 0) {
            success = true;
          }
        }
        if (success) {
          this.checkDialog();
        } else {
          this.$store.commit('main/alert', {
            typ: 'error',
            text: 'Fehler! Bitte die Netzwerkverbindung prüfen.',
          }, { root: true });
        }
        this.dialogDelAbrechnung = false;
      });
    },
    saveAbrechnung() {
      const timeLastEdit = new Date().getTime();
      const abrechnung = JSON.parse(JSON.stringify(this.abrechnung));
      abrechnung.tage = JSON.parse(JSON.stringify(this.tage));
      abrechnung.sonderzahlungen = [];
      this.sonderzahlungen.forEach((sz) => {
        if (sz.text !== '' && sz.wert > 0) {
          abrechnung.sonderzahlungen.push(sz);
        }
      });
      abrechnung.lohngruppen = JSON.parse(JSON.stringify(this.lohngruppen));
      abrechnung.timeLastEdit = timeLastEdit;
      this.$store.dispatch('f420/tryGetOrSaveSettings', {
        dataToSave: {
          type: 'saveAbrechnung',
          uebersichtData: this.getUebersichtData,
          abrechnung,
        },
      }).then(() => {
        let success = false;
        const m = this.$store.state.f420.store.mitarbeiter
          .filter((o) => o.id === this.idMitarbeiter);
        if (m.length > 0) {
          const a = m[0].lohnabrechnungen
            .filter((o) => o.jahr === this.jahr && o.monat === this.monat);
          if (a.length > 0) {
            if (a[0].timeLastEdit === abrechnung.timeLastEdit) {
              success = true;
              abrechnung.id = a[0].id;
              this.abrechnung.id = a[0].id;
            }
          }
        }
        if (success) {
          /* this.abrechnung.timeLastEdit = timeLastEdit;
          this.abrechnungSnapshot = JSON.stringify(this.abrechnung); */
          this.checkDialog();
        } else {
          this.$store.commit('main/alert', {
            typ: 'error',
            text: 'Fehler! Bitte die Netzwerkverbindung prüfen.',
          }, { root: true });
        }
      });
    },
    setTagStatus(tag, getStatus) {
      let status = getStatus;
      let force = false;
      if (getStatus === -1) {
        // Status -1 ermittelt automatisch was gefüllt werden soll:
        force = true;
        if (
          !tag.isWeekend
          || this.abrechnung.ausnahmen.filter((o) => o.weekday === tag.weekday).length > 0
        ) {
          const [ausnahme] = this.abrechnung.ausnahmen.filter((o) => o.weekday === tag.weekday);
          if (ausnahme) {
            status = ausnahme.status;
          } else {
            status = 1;
          }
        } else {
          status = 0;
        }
      }
      tag.status = status;
      if (status === 0) {
        tag.idLohngruppe = 0;
        tag.zeitVon = '';
        tag.zeitBis = '';
        tag.pausen = '';
        tag.objekt = '';
      } else if ([1, 2, 3, 4, 5].includes(status)) {
        const ausnahme = this.abrechnung.ausnahmen.filter((o) => o.weekday === tag.weekday);
        if (ausnahme.length > 0) {
          tag.ausnahme = true;
        }
        if (force) {
          tag.objekt = '';
        }
        if (
          tag.zeitVon === ''
          || force
        ) {
          tag.zeitVon = this.abrechnung.standard_arbeitsbeginn;
          if (ausnahme.length > 0) {
            if (ausnahme[0].arbeitsbeginn !== '') {
              tag.zeitVon = ausnahme[0].arbeitsbeginn;
            }
          }
        }
        if (
          tag.zeitBis === ''
          || force
        ) {
          tag.zeitBis = this.abrechnung.standard_arbeitsende;
          if (ausnahme.length > 0) {
            if (ausnahme[0].arbeitsende !== '') {
              tag.zeitBis = ausnahme[0].arbeitsende;
            }
          }
        }
        if (
          tag.pausen === ''
          || force
        ) {
          tag.pausen = this.abrechnung.standard_pausen;
          if (ausnahme.length > 0) {
            if (ausnahme[0].pausen !== '') {
              tag.pausen = ausnahme[0].pausen;
            }
          }
        }
        if (
          tag.idLohngruppe === 0
          || force
        ) {
          tag.idLohngruppe = this.abrechnung.standard_lohngruppe;
          if (ausnahme.length > 0) {
            if (ausnahme[0].lohngruppe > 0) {
              tag.idLohngruppe = ausnahme[0].lohngruppe;
            }
          }
        }
      }
    },
    changedStandardLohngruppe() {
      const ursprungsabrechnung = JSON.parse(this.abrechnungSnapshot);
      this.tage.forEach((t, idx) => {
        if (
          t.idLohngruppe === ursprungsabrechnung.standard_lohngruppe
          && !t.ausnahme
          && t.objekt === ''
        ) {
          this.tage[idx].idLohngruppe = this.abrechnung.standard_lohngruppe;
        }
      });
      ursprungsabrechnung.standard_lohngruppe = this.abrechnung.standard_lohngruppe;
      this.abrechnungSnapshot = JSON.stringify(ursprungsabrechnung);
      this.abrechnung.ueberstundenAusgezahlt.idLohngruppe = this.abrechnung.standard_lohngruppe;
    },
    changedStandardZeitVon() {
      const ursprungsabrechnung = JSON.parse(this.abrechnungSnapshot);
      this.tage.forEach((t, idx) => {
        if (
          t.zeitVon === ursprungsabrechnung.standard_arbeitsbeginn
          && !t.ausnahme
          && t.objekt === ''
        ) {
          this.tage[idx].zeitVon = this.abrechnung.standard_arbeitsbeginn;
        }
      });
      ursprungsabrechnung.standard_arbeitsbeginn = this.abrechnung.standard_arbeitsbeginn;
      this.abrechnungSnapshot = JSON.stringify(ursprungsabrechnung);
    },
    changedStandardZeitBis() {
      const ursprungsabrechnung = JSON.parse(this.abrechnungSnapshot);
      this.tage.forEach((t, idx) => {
        if (
          t.zeitBis === ursprungsabrechnung.standard_arbeitsende
          && !t.ausnahme
          && t.objekt === ''
        ) {
          this.tage[idx].zeitBis = this.abrechnung.standard_arbeitsende;
        }
      });
      ursprungsabrechnung.standard_arbeitsende = this.abrechnung.standard_arbeitsende;
      this.abrechnungSnapshot = JSON.stringify(ursprungsabrechnung);
    },
    changedStandardPausen() {
      const ursprungsabrechnung = JSON.parse(this.abrechnungSnapshot);
      this.tage.forEach((t, idx) => {
        if (
          t.pausen === ursprungsabrechnung.standard_pausen
          && !t.ausnahme
          && t.objekt === ''
        ) {
          this.tage[idx].pausen = this.abrechnung.standard_pausen;
        }
      });
      ursprungsabrechnung.standard_pausen = this.abrechnung.standard_pausen;
      this.abrechnungSnapshot = JSON.stringify(ursprungsabrechnung);
    },
    changedObjekt() {
      const objekte = [];
      this.tage.forEach((t) => {
        if (!objekte.includes(t.objekt) && t.objekt !== '') {
          objekte.push(t.objekt);
        }
      });
      this.datasetObjekte = objekte;
    },
    searchObjekt(search) {
      if (search && !this.datasetObjekte.includes(search) && search !== '') {
        this.datasetObjekte.push(search);
      }
    },
    changedStatus(tag) {
      this.setTagStatus(tag, tag.status);
      /* if (tag.status === 0) {
        // Nicht
        this.setTagStatus(tag, 0);
      } else if (tag.status === 1) {
        // Arbeit
        this.setTagStatus(tag, 1);
      } else if (tag.status === 2) {
        // Feiertag
      } else if (tag.status === 3) {
        // Urlaub
      } else if (tag.status === 4) {
        // Krank
      } */
    },
    changeMonath(m) {
      this.monat = m;
      this.getLohnabrechnung();
    },
    changeYear(y) {
      this.jahr = y;
      this.getLohnabrechnung();
    },
    checkDialog() {
      if (this.idMitarbeiter > 0) {
        this.showDialog = true;
        this.startLohnabrechnung();
      } else {
        this.showDialog = false;
      }
    },
    startLohnabrechnung() {
      this.loading = true;
      const loadingInterval = setInterval(() => {
        if (this.$store.state.f420.store.mitarbeiter) {
          if (
            this.$store.state.f420.store.mitarbeiter
              .filter((o) => o.id === this.idMitarbeiter)
              .length > 0
          ) {
            // Wenn die Mitarbeiterdaten erfolgreich geladen wurden,
            // deaktiviere den Intervall und gib die Daten aus.
            clearInterval(loadingInterval);
            [this.mitarbeiter] = this.$store.state.f420.store.mitarbeiter
              .filter((o) => o.id === this.idMitarbeiter);
            this.getLohnabrechnung();
          }
        }
      }, 100);
    },
    getLohnabrechnung() {
      if (
        !this.monat && !this.jahr
        && this.mitarbeiter.lohnabrechnungen.length > 0
      ) {
        // Wenn schon Lohnabrechnungen gemacht wurden, springe zum ersten Monat an dem noch keine
        // Abrechnung vorhanden ist
        let letztes = JSON.parse(JSON.stringify(this.mitarbeiter.lohnabrechnungen));
        letztes.sort();
        letztes = letztes.pop();
        letztes = new Date(`${letztes.jahr}-${String(letztes.monat).padStart(2, '0')}-01T00:00:00`);
        this.letzteAbrechnung = `${letztes.getFullYear()}-${letztes.getMonth() + 1}`;
        letztes.setMonth(letztes.getMonth() + 1);
        // console.log(letztes);
        this.jahr = letztes.getFullYear();
        this.monat = letztes.getMonth() + 1;
      } else if (!this.monat && !this.jahr) {
        // Wenn noch keine Lohnabrechnung vorhanden ist, springe zum aktuellen Monat
        this.letzteAbrechnung = '';
        this.jahr = new Date().getFullYear();
        this.monat = new Date().getMonth() + 1;
      }
      this.abrechnung = {};
      const lohnabrechnungen = this.mitarbeiter.lohnabrechnungen
        .filter((o) => o.jahr === this.jahr && o.monat === this.monat);
      const tag = new Date(`${this.jahr}-${String(this.monat).padStart(2, '0')}-01T00:00:00`);
      let tage = [];
      if (
        lohnabrechnungen.filter((o) => o.jahr === this.jahr && o.monat === this.monat)
          .length > 0
      ) {
        // Lade vorhandene Abrechnung
        this.abrechnung = JSON.parse(JSON.stringify(lohnabrechnungen[0]));
        tage = this.abrechnung.tage;
      } else {
        // Erstelle neue Abrechnung
        this.abrechnung = {
          id: 0,
          idMitarbeiter: this.idMitarbeiter,
          jahr: this.jahr,
          monat: this.monat,
          vertrag_stunden: this.mitarbeiter.vertrag_stunden,
          standard_arbeitsbeginn: this.mitarbeiter.standard_arbeitsbeginn,
          standard_arbeitsende: this.mitarbeiter.standard_arbeitsende,
          standard_pausen: this.mitarbeiter.standard_pausen,
          standard_lohngruppe: this.mitarbeiter.standard_lohngruppe,
          vertrag_lohngruppe: this.mitarbeiter.vertrag_lohngruppe,
          festgehalt: 0,
          ausnahmen: this.mitarbeiter.ausnahmen,
          ueberstundenAusgezahlt: {
            idLohngruppe: this.mitarbeiter.standard_lohngruppe,
            stunden: 0,
          },
          xSummeStunden: 0,
          xSummeUeberstunden: 0,
          xSummeUrlaubstage: 0,
        };
        if (this.mitarbeiter.festgehalt > 0) {
          this.abrechnung.festgehalt = this.mitarbeiter.festgehalt;
        }
        tage = [];
      }
      while (tag.getMonth() + 1 === this.monat) {
        // Iteriere alle Tage des Monats durch
        // und erstelle, wenn nicht vorhanden, einen neuen Tag:
        const neuTag = {
          date: tag.getDate(),
          dateText: `${tag.getFullYear()}-${String(tag.getMonth() + 1).padStart(2, '0')}-${String(tag.getDate()).padStart(2, '0')}`,
          dateText_de: `${String(tag.getDate()).padStart(2, '0')}.${String(tag.getMonth() + 1).padStart(2, '0')}.${tag.getFullYear()}`,
          dateText2_de: `${String(tag.getDate()).padStart(2, '0')}.${String(tag.getMonth() + 1).padStart(2, '0')}.`,
          monthText_de: `${String(tag.getMonth() + 1).padStart(2, '0')}.${tag.getFullYear()}`,
          monthText2_de: `${this.monate[tag.getMonth()].name} ${tag.getFullYear()}`,
          dayText_de: `${String(tag.getDate()).padStart(2, '0')}.`,
          weekday: tag.getDay(),
          isWeekend: [0, 6].includes(tag.getDay()),
          objekt: '',
          zeitVon: '',
          zeitBis: '',
          pausen: '',
          idLohngruppe: 0,
          status: 0, // 0: Nichts, 1: Arbeit, 2: Feiertag, 3: Urlaub, 4: Krank, 5: Gleittag
          ausnahme: false,
        };
        this.setTagStatus(neuTag, -1);
        if (tage.filter((o) => o.date === neuTag.date).length <= 0) {
          tage.push(neuTag);
        }
        tag.setDate(tag.getDate() + 1);
      }
      tage.forEach((t) => {
        if (t.objekt && !this.datasetObjekte.includes(t.objekt) && t.objekt !== '') {
          this.datasetObjekte.push(t.objekt);
        }
      });
      this.tage = tage;
      // Lohngruppen
      if (this.abrechnung.lohngruppen) {
        this.lohngruppen = this.abrechnung.lohngruppen;
      } else {
        this.lohngruppen = JSON.parse(JSON.stringify(
          this.$store.state.f420.store.settings.lohngruppen,
        ));
      }
      // Sonderzahlungen
      this.sonderzahlungen = [];
      if (this.abrechnung.sonderzahlungen) {
        this.sonderzahlungen = this.abrechnung.sonderzahlungen;
      }
      // Überstunden bis zu dieser Abrechnung ermitteln:
      let stundenSaldo = 0;
      this.mitarbeiter.lohnabrechnungen
        .filter((o) => {
          if (
            o.jahr === this.abrechnung.jahr // Nur Überstunden aus diesem Jahr beachten
            && `${o.jahr}-${o.monat}` < `${this.abrechnung.jahr}-${this.abrechnung.monat}`
          ) {
            return o;
          }
          return null;
        })
        .forEach((la) => {
          stundenSaldo += parseFloat(String(la.xSummeUeberstunden).split(',').join('.'), 10);
        });
      this.stundenSaldo = stundenSaldo;
      // Urlaub bis zu dieser Abrechnung ermitteln:
      let urlaubAusVorjahr = 0;
      if (this.monat === 1) {
        if (this.abrechnung.urlaubAusVorjahr) {
          urlaubAusVorjahr = this.abrechnung.urlaubAusVorjahr;
        }
      }
      this.urlaubAusVorjahr = urlaubAusVorjahr;
      let urlaubSaldo = this.mitarbeiter.urlaubstage;
      if (this.abrechnung.uebersichtData) {
        urlaubSaldo = this.abrechnung.uebersichtData.urlaubSaldo;
        urlaubSaldo -= urlaubAusVorjahr;
      } else {
        this.mitarbeiter.lohnabrechnungen
          .filter((o) => {
            if (
              o.jahr === this.abrechnung.jahr // Nur Urlaub aus diesem Jahr beachten
              && `${o.jahr}-${o.monat}` < `${this.abrechnung.jahr}-${this.abrechnung.monat}`
            ) {
              return o;
            }
            return null;
          })
          .forEach((la) => {
            if (la.monat === 1) {
              urlaubSaldo += la.urlaubAusVorjahr;
            }
            urlaubSaldo -= la.xSummeUrlaubstage;
          });
      }
      this.urlaubSaldo = urlaubSaldo;
      // Für Überstunden Auszahlungen verwende ide Standard-Lohngruppe
      this.abrechnung.ueberstundenAusgezahlt.idLohngruppe = this.abrechnung.standard_lohngruppe;
      // Ursprungszustand der Abrechnung sichern:
      const abrechnung = JSON.parse(JSON.stringify(this.abrechnung));
      abrechnung.tage = JSON.parse(JSON.stringify(this.tage));
      this.abrechnungSnapshot = JSON.stringify(abrechnung);
      // Wenn es Lohnabrechnungen nach dieser gibt, zeige nur eine Vorschau:
      const abrJetzt = `${this.jahr}-${this.monat}`;
      if (this.letzteAbrechnung > abrJetzt) {
        this.nurVorschau = true;
      } else {
        this.nurVorschau = false;
      }
      // Ladeanzeige beenden:
      this.loading = false;
    },
  },
  created() {
    this.checkDialog();
    // this.jahr = new Date().getFullYear();
    // this.monat = new Date().getMonth() + 1;
    this.jahr = null;
    this.monat = null;
  },
};
</script>
